import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Box, Heading } from 'rebass'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'

const Content = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 42px 0;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  h2 {
    font-size: 1.2em;
  }

  p {
    line-height: 140%;
    margin-bottom: 16px;
  }

  ul {
    margin-left: 16px;
  }

  li {
    list-style: circle;
    margin-bottom: 8px;
  }
`

const PrivacyPage = ({ data }) => {
  const { frontmatter, html } = data.allMarkdownRemark.edges[0].node
  const { title } = frontmatter

  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Box p={5} bg="#D9E8ED">
        <Heading
          fontSize={6}
          fontWeight="500"
          textAlign="center"
          fontFamily="Playfair Display, serif"
        >
          {title}
        </Heading>
      </Box>
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </Layout>
  )
}

export const PrivacyPageQuery = graphql`
  query PrivacyPage {
    allMarkdownRemark(
      filter: { frontmatter: { title: { eq: "Privacy Policy" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`

export default PrivacyPage
